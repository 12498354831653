// Helper function to get time ago string
export const getTimeAgo = (timestamp) => {
  // console.log("getTimeAgo timestamp", timestamp);
  const now = new Date();
  const past = new Date(timestamp);

  // Calculate exact difference in milliseconds for precision in smaller units
  const diffInMilliseconds = now - past;

  // Handle recent events with exact time comparisons
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  if (diffInSeconds < 60) {
    return `${diffInSeconds} second${diffInSeconds !== 1 ? 's' : ''} ago`;
  }

  const diffInMinutes = Math.floor(diffInMilliseconds / (60 * 1000));
  if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
  }

  const diffInHours = Math.floor(diffInMilliseconds / (60 * 60 * 1000));
  if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
  }

  // Normalize `now` and `past` to start of their days for day-based comparisons
  const nowDayStart = new Date(now);
  nowDayStart.setHours(0, 0, 0, 0);
  const pastDayStart = new Date(past);
  pastDayStart.setHours(0, 0, 0, 0);
  const dayDiffInMilliseconds = nowDayStart - pastDayStart;
  const diffInDays = Math.floor(dayDiffInMilliseconds / (24 * 60 * 60 * 1000));

  // Handle days and beyond with day-based normalization
  if (diffInDays < 30) {
    return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
  }

  const months = Math.floor(diffInDays / 30);
  if (months < 12) {
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  }

  const years = Math.floor(diffInDays / 365);
  return `${years} year${years !== 1 ? 's' : ''} ago`;
};