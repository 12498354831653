import React, { useState, useEffect, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import FDGrid from "../../FDGrid/FDGrid";
import formatNum from "../../../helpers/formatNum";
import Typography from "@mui/material/Typography";
import Brightness1 from "@mui/icons-material/Brightness1";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveIcon from '@mui/icons-material/Remove';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material/styles';
import { SiteLanguage } from "../../../Constants";
import {DetailPanel} from "./FDRecipeTableComponents/DetailPanel";
import DisplayConfigActions from "./FDRecipeTableComponents/DisplayConfigActions";
import { useSelector } from "react-redux";
import DisplayConfigToggles from "./FDRecipeTableComponents/DisplayConfigToggles";
import Chip from '@mui/material/Chip';
import DisplayConfigNotes from './FDRecipeTableComponents/DisplayConfigNotes';

import { v4 as uuidv4 } from "uuid";

const column_types = {
	delta: "delta",
	icon: "icon",
	bar: "bar",
	flagged: "flagged",
	actions: "actions",
	status_tracker: "status_tracker",
	notes: "notes",
	date: "date",
	number: "number",
}
//["number", "bar", "flagged", "delta"]
const num_columns = [column_types.number, column_types.bar, column_types.flagged, column_types.delta];
const pin_right_columns = [column_types.actions, column_types.status_tracker, column_types.notes];

const component_map = {
  dot: Brightness1,
  check: CheckCircleIcon,
  cross: ClearIcon,
  line: RemoveIcon,
  flag: FlagCircleIcon,
  down: ArrowDownwardIcon,
  up: ArrowUpwardIcon
};

function FDRecipeTable(props) {
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);
	const [extremes, setExtremes] = useState({});
	const theme = useTheme();
	const [visibleColumns, setVisibleColumns] = useState({});
	const [disableExportColumns, setDisableExportColumns] = useState({});
	const [pinnedColumns, setPinnedColumns] = useState({"left": [], "right": []});

	const tableFilters = useSelector((state) => state.dashboardFilters.filters[props.sourceTable]);
	// console.log(props.objectName, "displayConfigUuid:", props.displayConfigUuid);
	// console.log("sourceTable", props.sourceTable, "FD tableFilters", tableFilters);
	// console.log("displayConfigTableRows", displayConfigTableRows);
	const suppress_alert_toggle = useSelector((state) => state.dashboardFilters.displayConfigsToggleState.suppress_alert);
	const hide_row_toggle = useSelector((state) => state.dashboardFilters.displayConfigsToggleState.hide_row)

	const renderDelta = (column, output_value, raw_value) => {
		const MyComponent = component_map[raw_value > 0 ? "up" : "down"];
		return (<React.Fragment>{React.createElement(MyComponent, {})}<Typography variant="p" sx={{ marginLeft: "5px"}}>{output_value}</Typography></React.Fragment>)
	}
	
	const renderIcon = (column, value) => {
		let params = {};

		if(column.icon_config) {
			const component_type = column.icon_config.type ? column.icon_config.type : "dot";
			const MyComponent = component_map[component_type];
			if(value === column.icon_config.good) {
				params.sx = {color: theme.palette.good.main};
				return (<React.Fragment>{React.createElement(MyComponent, params)}</React.Fragment>)
			} else if(value === column.icon_config.bad) {
				params.sx = {color: theme.palette.bad.main};
				return (<React.Fragment>{React.createElement(MyComponent, params)}</React.Fragment>)
			}
			
		}
		
		return (<></>)
		
	}

	function LinearProgressWithLabel(props: LinearProgressProps & { value: number, output_value: string, bad: boolean }) {
	  return (
	    <Box sx={{ display: 'flex', alignItems: 'center' }}>
	      <Box sx={{ width: 100, mr: 1 }}>
	        <LinearProgress color={props.bad ? "bad" : "primary"} sx={{ height: "1.5em"}} variant="determinate" value={props.value} />
	      </Box>
	      <Box sx={{ minWidth: 35 }}>
	        <Typography variant="body2" color="text.secondary">{props.output_value}</Typography>
	      </Box>
	    </Box>
	  );
	}


	const renderBar = (column, value, output_value, width) => {
		//console.log(actualBar);
		let max = column.bar_config.max !== undefined ? column.bar_config.max : undefined;
		if(column.field in extremes && max === undefined) {
			max = extremes[column.field].max;
		}



		let min = column.bar_config.min !== undefined ? column.bar_config.min : undefined;
		if(column.field in extremes && min === undefined) {
			min = extremes[column.field].min;
		}


		return (<React.Fragment><LinearProgressWithLabel value={!isNaN(value) ? (value - min) / (max - min) * 100 : 0} output_value={output_value} bad={column.bar_config.bad ? column.bar_config.bad : false}></LinearProgressWithLabel></React.Fragment>)
	}

	const renderFlagged = (column, value, output_value) => {
		const good = column.flagged_config.invert ? value <= column.flagged_config.good : value >= column.flagged_config.good;
		const bad = column.flagged_config.invert ? value >= column.flagged_config.bad : value <= column.flagged_config.bad;

		if(good) {
			return(<React.Fragment><Typography sx={{ minWidth: "100px", textAlign: "center", backgroundColor: theme.palette.good.light, color: theme.palette.good.main }} variant="p">{output_value}</Typography></React.Fragment>)
		} else if (bad) {
			return(<React.Fragment><Typography sx={{ minWidth: "100px", textAlign: "center", backgroundColor: theme.palette.bad.light, color: theme.palette.bad.main }} variant="p">{output_value}</Typography></React.Fragment>)
		} else {
			return(<React.Fragment><Typography variant="p">{output_value}</Typography></React.Fragment>)
		}

		
	}

	const renderActions = (column, row, props) => {
		const {
			displayConfigUuid,
			displayConfigActionList,
			displayConfigSysAdminActionList,
			statusTrackerConfig,
			createEntryConfig,
			sendEmailConfig,
			disableActions
		} = props;
		return (
			<DisplayConfigActions
				column={column}
				row={row}
				displayConfigUuid={displayConfigUuid}
				displayConfigActionList={displayConfigActionList}
				displayConfigSysAdminActionList={displayConfigSysAdminActionList}
				statusTrackerConfig={statusTrackerConfig}
				createEntryConfig={createEntryConfig}
				sendEmailConfig={sendEmailConfig}
				disableActions={disableActions}
			/>
		)
	}

	const renderStatusTracker = (column, row, props) => {
		// all it's going to do is read "_status_tracker"
		const status = row["_status_tracker"];
		// FROM demo_accruals:
		// const statusStyleMap = {
		// 	"DRAFT": { fontSize: "16px"},
		// 	"APPROVED": {
		// 		backgroundColor: "rgba(228, 236, 255, 1.0)",
		// 		fontSize: "16px"
		// 	},
		// 	"POSTED": {
		// 		backgroundColor: "rgba(0, 18, 184, 1.0)",
		// 		color: "white !important",
		// 		fontSize: "16px"
		// 	}
		// }

		const getStatusStyle = (rowStatus) => {
			const defaultStyle = { fontSize: "10px" };
			// TODO: can we have chip display defaultStyle when hidden row?
			if (rowStatus === props.statusTrackerConfig.defaultStatus) {
				return defaultStyle;
			} else if (rowStatus === props.statusTrackerConfig.overrideColStatus) {
				return { ...defaultStyle, backgroundColor: "rgba(0, 18, 184, 1.0)", color: "white !important" }
			} else {
				return { ...defaultStyle, backgroundColor: "rgba(228, 236, 255, 1.0)" }
			}
		} 
		return (
			<Chip label={status} size="small" sx={getStatusStyle(status)}/>
		)
	}

	const renderNotes = (row) => {
		const { displayConfigUuid, disableActions } = props;
		return (
			<DisplayConfigNotes
				row={row}
				displayConfigUuid={displayConfigUuid}
				disableActions={disableActions}
			/>
		)
	}

	const numberSorter = (a, b) => {
		const numA = Number(a);
		const numB = Number(b);


		if (isNaN(numA)) return -1;
		if (isNaN(numB)) return 1;
		
		return numA - numB
	}

	const dateSorter = (a, b) => {
		const date1 = new Date(a);
		const date2 = new Date(b);

		const isDate1Invalid = isNaN(date1.getTime());
  	const isDate2Invalid = isNaN(date2.getTime());

		if (isDate1Invalid && isDate2Invalid) return 0; // Both are invalid, treat as equal
  	if (isDate1Invalid) return -1;  // date1 is invalid, place after date2
  	if (isDate2Invalid) return 1; // date2 is invalid, place date1 before date2

  	return date1 - date2;  // Comparing the date objects directly
	}

	useEffect(() => {
		let bar_columns = [];
		let new_extremes = {};

		for (var i = 0; i < props.columns.length; i++) {
			//console.log(props.columns);
			if(props.columns[i].type === "bar") {
				let sorted_array = [...props.rows];
				sorted_array.sort(function(a,b) {
					let aValue = a[props.columns[i]._fdOrder];
					let bValue = b[props.columns[i]._fdOrder];
					return aValue - bValue
				});

				new_extremes[props.columns[i].field] = {};
				if(sorted_array.length > 0) {
					new_extremes[props.columns[i].field].min = sorted_array[0][props.columns[i]._fdOrder];
					new_extremes[props.columns[i].field].max = sorted_array[sorted_array.length - 1][props.columns[i]._fdOrder];
				}
			}
		}
		setExtremes(new_extremes);

	}, [props.rows]);

	useEffect(() => {
		let staticTable = props.staticTable;
		let newVisibleColumns = JSON.parse(JSON.stringify(visibleColumns));
		let newDisableExportColumns = {};
		let rowFormatters = props.row_formatters;
		let newPinnedColumns = {"left": [], "right": []};

		let newColumns = props.columns.map((column, index) => {
			let classes = [];

			let output_columns = {
				'field': column.field,
				'headerName': column.header ? column.header : column.field,
				'width': column.width ? column.width : 150,
			}

			if(staticTable) {
				output_columns["sortable"] = !staticTable;
				classes.push("static-table");
			}

			if (column.hidden && column.hidden === true) {
				newVisibleColumns[column.field] = false;
				output_columns.hidden = true;// DetailPanel gets all columns using gridApi -> then creates own columnVisibilityModel based on this passed prop
			}

			if(column.type === column_types.bar) {
				output_columns.width = 200;
			}

			let column_type = column.type ? column.type : "string";

			if(column.pinned && column.pinned === true && /*column_type !== column_types.actions && column_type !== column_types.status_tracker*/!pin_right_columns.includes(column_type)) {
				newPinnedColumns["left"].push(column.field);
			}
			if (column.pinned && column.pinned === true && /*(column_type === column_types.actions || column_type === column_types.status_tracker)*/pin_right_columns.includes(column_type)) {// NEW for orders dashboard
				newPinnedColumns["right"].push(column.field);
			}

			// Separators
			if(column.separator_right) {
				classes.push("bordered-right");
			}
			if(column.separator_right_dense) {
				classes.push("bordered-right-dense");
			}
			if(column.separator_left) {
				classes.push("bordered-left");
			}

			// Bolding
			if(column.bold) {
				classes.push("bold");
			}

			if(column.disable_sorting) {
				output_columns["sortable"] = column.disable_sorting;
			}

			// Alignment
			output_columns["align"] = column.align ? column.align : "left";
			output_columns["align"] = column_type === column_types.icon ? "center" : output_columns["align"];


			output_columns.cellClassName = classes.join(" ");

			if (column.type && (num_columns.indexOf(column.type) > -1)) {
				output_columns["sortComparator"] = numberSorter;
			} else if (column.type && ([column_types.date].indexOf(column.type) > -1)) {
				output_columns["sortComparator"] = dateSorter;
			}

			output_columns["renderCell"] = (params) => {
				let prefix = column.prefix;
				let suffix = column.suffix;
				let digits = column.digits;
				let percent = column.percent;
				let string_override = false;

				if(rowFormatters) {
					let row_index = params.row.row_index;
					//console.log(params.row);


					for(var formatter = 0; formatter < rowFormatters.length; formatter++) {
						let column_value = params.row[rowFormatters[formatter].applied_column];
						

						if(rowFormatters[formatter].applied_rows.indexOf(column_value) > -1) {
							string_override = rowFormatters[formatter].no_formatting ? rowFormatters[formatter].no_formatting : false;

							prefix = rowFormatters[formatter].prefix ? rowFormatters[formatter].prefix : prefix;
							digits = rowFormatters[formatter].digits ? rowFormatters[formatter].digits : digits;
							suffix = rowFormatters[formatter].suffix ? rowFormatters[formatter].suffix : suffix;
							percent = rowFormatters[formatter].percent ? rowFormatters[formatter].percent : percent;
						}
					}
				}

				let output_value;
				if(string_override === true) {
					output_value = params.row[column.field];
				} else {
					let num_value =  formatNum(params.row[column.field], {min: digits ? digits : 0, max: digits ? digits : 0, percent: percent ? percent : false});

					if(column.type && (num_columns.indexOf(column.type) > -1) && num_value !== "-") {
						output_value =  formatNum(params.row[column.field], {min: digits ? digits : 0, max: digits ? digits : 0, percent: percent ? percent : false});
						output_value = ((prefix ? prefix : "") + output_value + (suffix ? suffix : ""));
					} 
					else if(column.type && (num_columns.indexOf(column.type) > -1) && num_value === "-") {
						output_value =  num_value;
					} else {
						output_value = params.row[column.field];
					}
				}
				
				if(column_type === column_types.delta) {
					return renderDelta(column, output_value, params.row[column.field]);
				} else if(column_type === column_types.icon) {
					return renderIcon(column, params.row[column.field]);
				} else if(column_type === column_types.bar) {
					return renderBar(column, params.row[column.field], output_value, output_columns["width"]);
				} else if(column_type === column_types.flagged) {
					return renderFlagged(column, params.row[column.field], output_value);
				} else if (column_type === column_types.actions) {// for orders dashboard
					return renderActions(column, params.row/*[column.field]*/, props);
				} else if (column_type === column_types.status_tracker) {
					return renderStatusTracker(column, params.row, props)
				} else if (column_type === column_types.notes) {
					return renderNotes(params.row)
				} else {
					return (<Typography variant="p">{output_value}</Typography>);
				}
			}

			// Hide actions column from export
			if (column_type === column_types.actions || column_type === column_types.notes) {
				newDisableExportColumns[column.field] = true;
			}

			// New for detailPanel/orders dashboard - passing config for DetailPanel apiRef columns DetailPanel accesses
			if (column.detail_config) {
				output_columns.detail_config = column.detail_config;
			}

			// Apply cell_style_config to output column so getCellClassName can apply style individual cells with App.js createTheme defined cell styles
			if (column.cell_style_config) {
				output_columns.cell_style_config = column.cell_style_config;
			}

			// Flex columns - not used
			if (column.flex) {
				output_columns.flex = column.flex;
			}

			// console.log("output_columns", output_columns);
			return output_columns
		});
		setColumns(newColumns);
		setPinnedColumns(newPinnedColumns);

		let newRows = props.rows.map((row, index) => {
			const rowJson = {"row_index": index}
			props.columns.map(col => {
				rowJson[col.field] = row[col["_fdOrder"]]
			});
			return rowJson;
		})
		
		if(props.sorting_column) {
			newRows = newRows.sort((a, b) => {
				return props.sorting_direction && props.sorting_direction === "desc" ? b[props.sorting_column] - a[props.sorting_column] : a[props.sorting_column] - b[props.sorting_column]
			});
		}

		setDisableExportColumns(newDisableExportColumns);
		setVisibleColumns(newVisibleColumns);
		setRows(newRows);

	}, [extremes, props.rows]);

	/*
	useEffect(() => {
		if(props.linked_variable) {
			let newColumns = columns.map((column, index) => {
				let updated_column = JSON.parse(JSON.stringify(column));
				let classes = updated_column.cellClassName.split(" ");
				//classes.push("highlighted");
				//updated_column.cellClassName = classes.join(" ");
				updated_column.cellClassName = "highlighted";
				return updated_column;
			});
			setColumns(newColumns);
		}
	}, [props.dashboardDict]);
	*/

	const rowFormatting = (params) => {
		let rowClasses = [];
		// TODO: if !suppress_alert (set via to be built toggle button) - how to show user it is a suppress_alert === true row? (maybe background color?)
		if (props.statusTrackerConfig) {
			// console.log("statusTrackerConfig", props.statusTrackerConfig);
			const { row } = params;
			if (row["_status_tracker"] !== props.statusTrackerConfig.defaultStatus && row["_status_tracker"] !== props.statusTrackerConfig.overrideColStatus) {
				// console.log(row["_status_tracker"])
				rowClasses.push("status-in-progress")
			} else if (row["_status_tracker"] === props.statusTrackerConfig.overrideColStatus) {
				rowClasses.push("status-done")
			}
		}

		// TODO: if !hide_row (set via to be built toggle button) - how to show user it is a hide_row === true row? (maybe backgroundColor? maybe italics?
		if (!hide_row_toggle) {
			// console.log("!hide_row_toggle params", params);
			const { row } = params;
			const matchingRow = Object.values(tableFilters).find((filterProperties) => {
				// valueMap is unique to displayConfig filters
				return filterProperties.valueMap && Object.entries(filterProperties.valueMap).every(([key, value]) => row[key] === value) && filterProperties.displayConfigOptions.hide_row?.optionValue;
			})
			if (matchingRow) {
				rowClasses = rowClasses.filter(
					(className) => className !== "status-in-progress" && className !== "status-done"
				);
				rowClasses.push("dashboard-hidden-row");
				// rowClasses.push("dashboard-hidden-row")
			}
		}

		if(props.spacerRows && props.spacerRows.indexOf(params.indexRelativeToCurrentPage) > -1) {
			rowClasses.push("spacer-row")
		}
		if(props.boldedRows && props.boldedRows.indexOf(params.indexRelativeToCurrentPage) > -1) {
			rowClasses.push("bold")
		}
		if(props.italicsRows && props.italicsRows.indexOf(params.indexRelativeToCurrentPage) > -1) {
			rowClasses.push("italics")
		}
		if(props.topSeparatorRows && props.topSeparatorRows.indexOf(params.indexRelativeToCurrentPage) > -1) {
			rowClasses.push("bordered-top")
		}

		return rowClasses
	}

	// to support individual cell styling for orders dashboard (see column.cell_style_config)
	const getCellClassName = (params) => {
		const { colDef, value, row } = params;

		let suppressAlert = false;
		if (props.displayConfigEnabled && suppress_alert_toggle) {
			const suppressAlertFilters = Object.values(tableFilters).filter((filterProperties) => filterProperties.valueMap && filterProperties.displayConfigOptions.suppress_alert?.optionValue);
			for (let i = 0; i < suppressAlertFilters.length; i++) {
				if (Object.entries(suppressAlertFilters[i].valueMap).every(([field, value]) => row[field] === value)) {
					suppressAlert = true;
				}
			}
		}

		if (suppressAlert) return '';
		if (colDef.cell_style_config) {
			if (colDef.cell_style_config.cellFormatConditionColumn) {
				const applyStyle = row[colDef.cell_style_config.cellFormatConditionColumn];
				return applyStyle ? colDef.cell_style_config.cellFormatType : "";
			} else {
				if (colDef.cell_style_config.valueExceptions) {
					return colDef.cell_style_config.valueExceptions.includes(value) ? "" : colDef.cell_style_config.cellFormatType
				}
				return colDef.cell_style_config.cellFormatType;
			}
		}
		return '';
	}
	// console.log("FDRecipeTable columns", columns);
	// console.log("FDRecipeTable rows", rows);

	return (
			<Grid container spacing={2}>
				<Grid item xs={12} className="table-label">
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<Typography variant="h6">{props.title}</Typography>
						<DisplayConfigToggles
							displayConfigUuid={props.displayConfigUuid}
							displayConfigActionList={props.displayConfigActionList}
							displayConfigSysAdminActionList={props.displayConfigSysAdminActionList}
							disableActions={props.disableActions}
						/>
					</div>
				{/*	<Typography variant="h6">{props.cleanTitle(props.title)}</Typography>*/}
				</Grid>
				{props.subtitle && <Grid item xs={12} className="table-label">
					<Typography variant="p">{props.subtitle}</Typography>
				</Grid>}
				<Grid item xs={12} className="table-label">
					<FDGrid
						rows={rows}
						columns={columns}
						exportFilename={props.title}
						pageSize={props.pageSize ? props.pageSize : 10}
						rowsPerPageOptions={[5]}
						autoHeight={true}
						staticTable={props.staticTable ? props.staticTable : false}
						pinnedColumns={pinnedColumns}
						getRowId = {(row) => row.row_index}
						getRowClassName={rowFormatting}
						columnVisibilityModel = {visibleColumns}
						localeText={{
							noRowsLabel: <Typography variant="p" sx={{ color: theme.typography.missingData.color, fontWeight: theme.typography.p.fontWeight, fontSize: theme.typography.h6.fontSize }}>{SiteLanguage.MissingData}</Typography>,
						}}
						density={"compact"}
						detailPanelEnabled={props.hasDetailColumns ? props.hasDetailColumns : false}
						detailPanelContent={DetailPanel}
						detailPanelDisplayNameOrder={props.hasDetailColumns && props.detailColumnDisplayNameOrder ? props.detailColumnDisplayNameOrder : []}
						detailPanelDisplayNameColumn={props.hasDetailColumns && props.detailDisplayNameColumn ? props.detailDisplayNameColumn : ""}
						getCellClassName={getCellClassName}
						disableExportColumns={disableExportColumns}
					/>
				</Grid>
			</Grid>
	)
}

export default FDRecipeTable;