export const newCreateEntryForm = () => {
  return {
    debitAccounts: [],
    creditAccounts: [],
    journalEntryMemo: "",
    previouslySubmitted: false,// true if already "create_entry" in displayConfigOptions
    currency: "",// TODO: this will come from specified column in createEntryConfig
    attachedFiles: [],
    referenceInfo: "",
    accountNameToIdMap: null, // will be null OR { <accountName>: <accountId>, ... }
    clientEmail: {},
    clientEmailExpectedAmount: ""
    // clientEmailExpectedAmount is the first value of the row's row[createEntryConfig.debitAccountAmountListColumn]?.split(";") 
    // if the scripts run correctly it is also the total emailed to the vendor for approval - will be consumed in ClientEmailMessage
  }
}

export const form_fields = {
  debitAccounts: "debitAccounts",
  creditAccounts: "creditAccounts",
  journalEntryMemo: "journalEntryMemo",
  previouslySubmitted: "previouslySubmitted",
  currency: "currency",
  attachedFiles: "attachedFiles",
  referenceInfo: "referenceInfo",
  accountNameToIdMap: "accountNameToIdMap",
  clientEmail: "clientEmail",
  clientEmailExpectedAmount: "clientEmailExpectedAmount",
}

export const currency_types = {
  USD: "USD"
}

export const newAccountDict = (acctId, acctName, acctAmt, acctLineMemo) => {
  return {
    id: acctId,
    acct: acctName,//string
    amt: acctAmt,//string -> will be checked for validity - error will flip isErrored and apply errorMessage for helpertext
    line_memo: acctLineMemo
  }
}

export const acct_fields = {
  id: "id",
  acct: "acct",
  amt: "amt",
  line_memo: "line_memo"
}

export const isValidAmount = (value) => {
  const regex = /^(\d{1,3}(,\d{3})*|\d+)(\.\d{0,2})?$/;
  return regex.test(value);
}

