import Big from 'big.js';
import CreateEntryInput from '../../../Dashboard/components/FDRecipeTableComponents/DisplayConfigActionsComponents/CreateEntryConfigComponents/CreateEntryInput';
import { Popover, FormControl, FormLabel, RadioGroup, FormControlLabel, Button, Radio, FormHelperText } from "@mui/material";
import { useState, useEffect } from "react";
import * as Constants from "../../../../Constants";

const isValidAmount = (input) => {
  try {
    // Remove any currency symbols and whitespace
    const cleanInput = input.replace(/[$,\s]/g, '');
    
    // Try to create a Big number from the input
    const amount = new Big(cleanInput);
    
    // Check if it has maximum 2 decimal places
    const decimalPlaces = amount.c.length > amount.e + 1 ? 
      amount.c.length - (amount.e + 1) : 0;
    
    return decimalPlaces <= 2;
  } catch (e) {
    // If Big.js throws an error, the input is invalid
    return false;
  }
};

const validateForm = (formState) => {
  if (formState.adjustmentNeeded_override === null) return false;
  if (formState.adjustmentNeeded_override && !isValidAmount(formState.adjustedAmount_override)) return false;
  return true;
};

const convertAmountStringToFloat = (amountString) => {
  // Input is already validated, so we can just convert directly
  const cleanAmount = amountString.replace(/[$,\s]/g, '');
  const bigAmount = new Big(cleanAmount);
  return bigAmount.toFixed(2);
};

const handleFormUpdate = (newValue, field, formState) => {
  return {
    ...formState,
    [field]: newValue
  }
}

const getAdjNeededInputState = (formState) => {
  return {
    value: formState.adjustmentNeeded_override,
    label: "adjustmentNeeded_override",
    error: formState.adjustmentNeeded_override === null,
    errorMessage: formState.adjustmentNeeded_override === null ? <span style={{ fontSize: "8px" }}>Please select an override value</span> : ""
  }
}

const getAdjAmountInputState = (formState) => {
  return {
    value: formState.adjustedAmount_override,
    label: "adjustedAmount_override",
    error: !isValidAmount(formState.adjustedAmount_override),
    errorMessage: !isValidAmount(formState.adjustedAmount_override) ? <span style={{ fontSize: "8px" }}>Valid amount required</span> : ""
  }
}

const BooleanRadio = ({label, handleUpdate, error, errorMessage, value}) => {
  return (
    <FormControl error={error} size="small" sx={{ width: "100%" }}>
      <FormLabel 
        id="boolean-radio-group"
        style={{ 
          transform: 'scale(0.75)',
          transformOrigin: 'left'
        }}
      >
        {label}
      </FormLabel>
      <RadioGroup
        row
        value={value === null ? '' : String(value)} // Convert boolean to string since RadioGroup expects string values
        aria-labelledby="boolean-radio-group"
        onChange={(e) => handleUpdate(e.target.value === 'true')}
      >
        <FormControlLabel 
          value="true" 
          control={<Radio />} 
          label="True" 
        />
        <FormControlLabel 
          value="false" 
          control={<Radio />} 
          label="False" 
        />
      </RadioGroup>
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

const OverrideAttributesForm = ({uuid, attributes, open, anchorRef, handleClose, handleAttributesUpdate}) => {
  const [formState, setFormState] = useState({
    adjustmentNeeded_override: null,// needs to be bool to send
    adjustedAmount_override: "0.00"
  })

  useEffect(() => {
    if (attributes) {
      setFormState(prevState => ({
        ...prevState,
        adjustmentNeeded_override: attributes.adjustmentNeeded_override ?? null,
        adjustedAmount_override: attributes.adjustedAmount_override ? String(attributes.adjustedAmount_override) : "0.00"
      }));
    }
  }, [attributes]);

  const valid = validateForm(formState);
  const adjNeededInputState = getAdjNeededInputState(formState);
  const adjAmountInputState = getAdjAmountInputState(formState);

  const handleAdjustmentNeededChange = (newValue) => {
    const newFormState = handleFormUpdate(newValue, "adjustmentNeeded_override", formState)
    setFormState(newFormState)
  }

  const handleAdjustedAmountChange = (newValue) => {
    const newFormState = handleFormUpdate(newValue, "adjustedAmount_override", formState)
    setFormState(newFormState)
  }

  const handleSubmit = () => {
    /*
    expected payload:
    {
      "clientEmailUuid": "e_XXXXXXXXXXXXXXXXXXXXXX",
      "adjustmentNeeded_override": true,
      "adjustedAmount_override": 0.00
    }
    */
    const body = {
      ...formState,
      clientEmailUuid: uuid,
      adjustedAmount_override: convertAmountStringToFloat(formState.adjustedAmount_override)
    }
    const tempUpdateArgs = {
      url: Constants.SERVER_SYSADMIN_CLIENT_EMAIL_ATTRIBUTES_OVERRIDE_URL,
      method: "POST",
      body: JSON.stringify(body)
    }
    handleAttributesUpdate(tempUpdateArgs);
    handleClose();
    setFormState({
      adjustmentNeeded_override: null,// needs to be bool to send
      adjustedAmount_override: "0.00"
    })
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorRef.current}
      onClose={handleClose}
      anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
      }}
    >
      <div style={{ 
        padding: 10, 
        width: "350px", 
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "8px"
      }}>
        <span style={{ width: "100%" }}>Override attributes</span>
        
        <BooleanRadio 
          value={adjNeededInputState.value}
          label={adjNeededInputState.label}
          handleUpdate={handleAdjustmentNeededChange}
          error={adjNeededInputState.error}
          errorMessage={adjNeededInputState.errorMessage}
        />
        
        <CreateEntryInput
          value={adjAmountInputState.value}
          handleUpdate={handleAdjustedAmountChange}
          label={adjAmountInputState.label}
          error={adjAmountInputState.error}
          errorMessage={adjAmountInputState.errorMessage}
        />
        
        <Button
          onClick={handleSubmit}
          disabled={!valid}
          variant="contained"
          color="primary"
          size="small"
          style={{ width: "100%" }}
        >
          Save
        </Button>
      </div>
    </Popover>
  )
}

export default OverrideAttributesForm;