import { useState } from "react";
import { getDisplayConfigsArgs } from "../../../../API/requests/DashboardRequest";
import { config_options } from "../../../../store/dashboardConfigsSlice";
import * as Constants from "../../../../Constants";

const initialSelectedAppDict = {
  uuid: "",
  getDataArgs: null,// OR object
  getDisplayConfigsArgs: null,// OR array of objects
  clientEmailUuids: [],
  getClientEmailArgs: null,// OR array of objects
  clientEmails: null,// OR array of objects
  clientEmailUuid: "",
  isLoading: false,
  isReady: false,
  updateArgs: null,
  getUpdatedAttributesArgs: null
};

export const useClientEmails = (selectedCompany, selectedCompanySubscribedApps) => {
  const [selectedAppDict, setSelectedAppDict] = useState(initialSelectedAppDict);

  const handleSelection = (event) => {
    if (event.target.value) {
      const selectedAppUuid = event.target.value;
      const customerSubscriptionUuid = selectedCompanySubscribedApps.find(
        subscription => subscription.developerAppUuid === selectedAppUuid
      ).uuid;
      
      setSelectedAppDict({
        ...initialSelectedAppDict,
        uuid: selectedAppUuid,
        getDataArgs: {
          url: Constants.SERVER_SYSADMIN_CUS_SUBBED_APP_URL + selectedCompany.uuid,
          method: "POST",
          body: JSON.stringify({ customerSubscriptionUuid }),
        },
        isLoading: true,
      });
    }
  };

  const handleSelectedAppData = (res) => {
    const dashboardJson = JSON.parse(res.dashboardJson);
    let entryPeriod = null;
    Object.entries(dashboardJson.components).forEach(([objectName, component]) => {
      if (entryPeriod) return;
      if (component.createEntryConfig && component.createEntryConfig.entryPeriod) {
        entryPeriod = component.createEntryConfig.entryPeriod;
      }
    })
    const tempDisplayConfigsArgs = getDisplayConfigsArgs(dashboardJson, selectedAppDict.uuid, selectedCompany.uuid, entryPeriod)
    if (tempDisplayConfigsArgs.length === 0) {
      setSelectedAppDict(prevDict => {
        return {
          ...prevDict,
          isLoading: false,
          isReady: true
        }
      })
    } else {
      setSelectedAppDict(prevDict => {
        return {
          ...prevDict,
          getDisplayConfigsArgs: tempDisplayConfigsArgs
        }
      })
    }
  }

  const handleDisplayConfig = (res) => {
    // console.log("handleDisplayConfig - res", res)
    const thisDisplayConfig = res.displayConfigTable;
    const tempClientEmailUuids = [...selectedAppDict.clientEmailUuids];
    thisDisplayConfig.displayConfigTableRows.forEach(tableRow => {
      if (tableRow.displayConfigOptions[config_options.send_email] && tableRow.displayConfigOptions[config_options.send_email].optionValue) {
        tempClientEmailUuids.push(tableRow.displayConfigOptions[config_options.send_email].optionValue)
      }
    })
    if (selectedAppDict.getDisplayConfigsArgs.length === 1) {
      // last displayConfig...
      // generate getClientEmailArgs for each clientEmailUuid (res contains metadat about the clientEmail - llm review state, send and response timestamps, etc)
      // generate getClientEmailContentArgs for each clientEmailUuid ("content" res contains subject, body, response body, etc for display here)
      const tempGetClientEmailArgs = [];
      // const tempGetClientEmailContentArgs = [];
      tempClientEmailUuids.forEach(clientEmailUuid => {
        const tempEmailArgs = {
          url: Constants.SERVER_SYSADMIN_GET_CLIENT_EMAIL_URL + selectedCompany.uuid,
          method: "POST",
          body: JSON.stringify({
            clientEmailUuid: clientEmailUuid
          })
        }
        // const tempEmailContentArgs = {
        //   url: Constants.SERVER_SYSADMIN_GET_CLIENT_EMAIL_CONTENT_URL + clientEmailUuid,
        //   method: "GET"
        // }
        tempGetClientEmailArgs.push(tempEmailArgs);
        // tempGetClientEmailContentArgs.push(tempEmailContentArgs);
      })
      // if no clientEmailUuids - isLoading = false
      if (tempClientEmailUuids.length === 0) {
        setSelectedAppDict(prevDict => {
          return {
            ...prevDict,
            getDisplayConfigsArgs: null,
            isLoading: false,
            isReady: true
          }
        })
      } else {
        // now set clientEmailArgs to dict and set getDisplayConfigsArgs back to null
        setSelectedAppDict(prevDict => {
          return {
            ...prevDict,
            getDisplayConfigsArgs: null,
            clientEmailUuids: tempClientEmailUuids,
            getClientEmailArgs: tempGetClientEmailArgs
          }
        })
      }
    } else {
      // slice getDisplayConfigsArgs
      setSelectedAppDict(prevDict => {
        return {
          ...prevDict,
          getDisplayConfigsArgs: prevDict.getDisplayConfigsArgs.slice(1),
          clientEmailUuids: tempClientEmailUuids
        }
      })
    }
  }

  const handleClientEmail = (res) => {
    const clientEmail = {...res.file};
    const tempClientEmails = selectedAppDict.clientEmails === null 
      ? [clientEmail] 
      : [...selectedAppDict.clientEmails, clientEmail];
    
    if (selectedAppDict.getClientEmailArgs.length === 1) {
      // Sort tempClientEmails by emailSendTimestamp (most recent to least recent)
      tempClientEmails.sort((a, b) => new Date(b.emailSendTimestamp) - new Date(a.emailSendTimestamp));
      // and wipe getClientEmailArgs
      setSelectedAppDict(prevDict => {
        return {
          ...prevDict,
          clientEmails: tempClientEmails,
          getClientEmailArgs: null,
          isLoading: false,
          isReady: true
        }
      })
    } else {
      // slice getClientEmailArgs and getClientEmailContentArgs
      setSelectedAppDict(prevDict => {
        return {
          ...prevDict,
          clientEmails: tempClientEmails,
          getClientEmailArgs: prevDict.getClientEmailArgs.slice(1),
        }
      })
    }
  }

  const handleEmailSelection = (clientEmailUuid) => {
    if (clientEmailUuid) {
      // set clientEmailUuid
      setSelectedAppDict(prev => ({
        ...prev,
        clientEmailUuid: clientEmailUuid
      }));
    } else {
      // clear clientEmailUuid
      setSelectedAppDict(prev => ({
        ...prev,
        clientEmailUuid: ""
      }));
    }
  };

  const handleAttributesUpdate = (updateArgs) => {
    setSelectedAppDict(prevSelectedAppDict => {
      return {
        ...prevSelectedAppDict,
        updateArgs: updateArgs
      }
    })
  }

  const handleAttributesUpdateSuccess = (res) => {
    // what will response be?
    // console.log("handleAttributesUpdateSuccess res", res)
    // res will be nothing useful - could append attributes on success 
    const updateBody = JSON.parse(selectedAppDict.updateArgs.body);
    const { clientEmailUuid } = updateBody;
    const tempUpdatedAttributesArgs = {
      url: Constants.SERVER_SYSADMIN_GET_CLIENT_EMAIL_URL + selectedCompany.uuid,
      method: "POST",
      body: JSON.stringify({
        clientEmailUuid: clientEmailUuid
      })
    }
    setSelectedAppDict(prevSelectedAppDict => {
      return {
        ...prevSelectedAppDict,
        updateArgs: null,
        getUpdatedAttributesArgs: tempUpdatedAttributesArgs
      }
    })
  }

  const handleUpdatedAttributes = (res) => {
    const updatedClientEmail = {...res.file};
    setSelectedAppDict(prevSelectedAppDict => {
      return {
        ...prevSelectedAppDict,
        clientEmails: prevSelectedAppDict.clientEmails.map(clientEmail => {
          if (clientEmail.uuid === updatedClientEmail.uuid) {
            return {
              ...clientEmail,
              attributes: {
                ...updatedClientEmail.attributes
              }
            }
          } else {
            return clientEmail
          }
        }),
        getUpdatedAttributesArgs: null
      }
    })
  }

  const resetClientEmails = () => {
    setSelectedAppDict(initialSelectedAppDict)
  }

  return {
    selectedAppDict,
    handleSelection,
    handleSelectedAppData,
    handleDisplayConfig,
    handleClientEmail,
    handleEmailSelection,
    handleAttributesUpdate,
    handleAttributesUpdateSuccess,
    handleUpdatedAttributes,
    resetClientEmails,
    setSelectedAppDict
  };
};