import { Grid, Button } from "@mui/material";
import { form_fields, acct_fields, isValidAmount } from "./createEntryForm";
import Big from 'big.js';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

const getAllAccounts = (formState) => {
  // console.log("getAllAccounts - formState", formState)
  return [...formState[form_fields.debitAccounts], ...formState[form_fields.creditAccounts]];
}

const accountIdsValid = (allAccounts) => {
  return allAccounts.every(account => account[acct_fields.id]);
}

const accountsValid = (allAccounts) => {
  return allAccounts.every(account => account[acct_fields.acct]);
}

const amountsValid = (allAccounts) => {
  return allAccounts.every(account => isValidAmount(account[acct_fields.amt]));
}

const lineMemosValid = (allAccounts) => {
  return allAccounts.every(account => !account[acct_fields.line_memo].includes(";"))
}

const getTotalAmountV3 = (debitAccounts, creditAccounts) => {
  // Helper function to clean and convert amount to Big
  const toBigAmount = (amountStr) => {
    const cleanAmount = amountStr.replace(/,/g, ''); // Remove commas
    return new Big(cleanAmount || '0'); // Default to 0 if the string is empty
  };

  // Calculate the total for debit accounts
  const totalDebits = debitAccounts.reduce((sum, account) => {
    const amount = toBigAmount(account[acct_fields.amt]);
    return sum.plus(amount);
  }, new Big(0));

  // Calculate the total for credit accounts
  const totalCredits = creditAccounts.reduce((sum, account) => {
    const amount = toBigAmount(account[acct_fields.amt]);
    return sum.plus(amount);
  }, new Big(0));

  // Subtract total debits from total credits and return result as a string with 2 decimal places
  return totalCredits.minus(totalDebits).toFixed(2);
}

const validateJournalEntry = (formState) => {
  // Combine both arrays into one for easier processing
  const allAccounts = getAllAccounts(formState);

  // Validate all account ids
  const allAccountIdsValid = accountIdsValid(allAccounts)

  // Validate all account names
  const allAccountsValid = accountsValid(allAccounts);

  // Validate all amounts
  const allAmountsValid = amountsValid(allAccounts);

  // Validate all line memos
  const allLineMemosValid = lineMemosValid(allAccounts);

  if (!allAccountIdsValid || !allAccountsValid || !allAmountsValid || !allLineMemosValid) {
    // If any amount is invalid, return true
    return true;
  }

  const totalAmount = getTotalAmountV3(formState[form_fields.debitAccounts], formState[form_fields.creditAccounts]);
  console.log("validateJournalEntry - totalAmount", totalAmount)

  // If the total is 0, return false; otherwise, return true
  return totalAmount !== '0.00';
};

const checkForm = (formState) => {
  // are there source and destination accounts?
  if (formState[form_fields.debitAccounts].length === 0 || formState[form_fields.creditAccounts].length === 0) {
    return true
  }
  return validateJournalEntry(formState);
}

const SumHelperText = ({formState}) => {
  const allAccounts = formState ? getAllAccounts(formState) : [];
  const allAmountsValid = amountsValid(allAccounts);
  const totalAmount = allAmountsValid ? getTotalAmountV3(formState[form_fields.debitAccounts], formState[form_fields.creditAccounts]) : 0;
  return !allAmountsValid || (allAmountsValid && totalAmount !== '0.00') ? (
    // <div style={{ position: "absolute", left: -265, top: 10 }}>
    //   <span style={{ fontSize: "10px", color: "#d32f2f" }}>
    //     <i>
    //       Journal entry not balanced - ensure sum equals 0
    //     </i>
    //   </span>
    // </div>
    <div style={{
      position: "absolute",
      right: "100%", // Positions it immediately to the left of the button
      top: "50%", // Centers vertically relative to the button
      transform: "translateY(-50%)", // Ensures perfect vertical centering
      marginRight: "12px", // Adds some spacing between text and button
      textAlign: "right",
      whiteSpace: "nowrap" // This prevents unwanted word wrapping
    }}>
      <span style={{ 
        fontSize: "10px", 
        color: "#d32f2f",
        display: "block" // Makes each line break naturally
      }}>
        <i>
          Journal entry not balanced<br />
          ensure sum equals 0
        </i>
      </span>
    </div>
  ) : null;
}

export const SubmitButton = ({ formState, handleSubmit }) => {
  const buttonText = formState[form_fields.previouslySubmitted] ? "UPDATE JE CSV" : "ADD TO JE CSV";
  return (
    <Grid item sx={{ position: "relative" }}>
      <SumHelperText formState={formState}/>
      <Button
        variant="contained"
        onClick={() => handleSubmit(formState)}
        disabled={checkForm(formState)}
        sx={{ textTransform: 'none' }}
        startIcon={formState[form_fields.previouslySubmitted] ? <PlaylistAddCheckIcon/> : <PlaylistAddIcon/>}
      >
        {buttonText}
      </Button>
    </Grid>
  )
}

export const DeleteButton = ({ formState, handleDelete }) => {
  return formState[form_fields.previouslySubmitted] ? (
    <Grid item sx={{ marginLeft: "16px"}}>
      <Button
        variant="outlined"
        color="error"
        onClick={() => handleDelete(formState)}
      >
        Delete
      </Button>
    </Grid>
  ) : null;
}

export const UpdateButtons = ({ formState, handleSubmit, handleDelete }) => {
  return (
    <Grid item xs={12} container justifyContent={"center"}>
      <SubmitButton formState={formState} handleSubmit={handleSubmit}/>
      <DeleteButton formState={formState} handleDelete={handleDelete}/>
    </Grid>
  )
}

export default UpdateButtons;