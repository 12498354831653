import { Tooltip, IconButton, Button, Popover } from "@mui/material";
import {getIcon, getIconButtonStyle} from "./ConfigActionUtils";
import React, {useRef, useState} from "react";

const StatusButton = ({ status, handleClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Button
      onClick={() => handleClick(status)}
      variant={isHovered ? "contained" : "outlined"}
      color="primary"
      size="small"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ marginTop: 8, width: "100%" }}
    >
      {status}
    </Button>
  )
}

export const StatusTrackerConfigActionButton = ({handleConfigUpdate, configAction, displayConfigTableRow, statusTrackerConfig, disableActions}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
    }

    setOpen(false);
  };

  const handleClick = (newStatus) => {
    handleConfigUpdate(newStatus);
    setOpen(false);
  }

  return (
    <>
      <Tooltip title={"Change status"} followCursor>
        <IconButton tabIndex={-1} sx={getIconButtonStyle(configAction)} onClick={handleToggle} disabled={disableActions} ref={anchorRef}>
          {getIcon("", configAction, disableActions)}
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
      >
        <div style={{ padding: 10, width: "350px", borderRadius: "10px" }}>
          {statusTrackerConfig["allowedStatusList"].map((status, index) => {
            return (
              <StatusButton key={`${status}_${index}`} status={status} handleClick={handleClick} />
            )
          })}
        </div>
      </Popover>
    </>
  )
}

export default StatusTrackerConfigActionButton;