import { useClientEmails } from "./hooks/useClientEmails";
import ClientEmail from "./components/ClientEmail";
import ClientEmailSummary from "./components/ClientEmailSummary";
import { FormControl, Select, InputLabel, Grid, MenuItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import * as Constants from "../../../Constants";
import DirectRequest from "../../../API/requests/DirectRequest";
import { useSelector } from "react-redux";

const getSourceTag = (tags) => {
  const sourceDisplayNames = Constants.supported_integrations.map((integration) => integration.displayName.toLowerCase()).concat(["sage"]);
  return tags.find((tag) => sourceDisplayNames.includes(tag.toLowerCase())) || "source tag not found";
}

const getLinkConfigSysAdminTitle = (tags) => {
  const linkConfigTag = tags.find(tag => tag.includes("link_config_sysadmin__"));
  return linkConfigTag.split("__")[1].split("_").join(" ");
}

const SysAdminLinkConfigAppSelect = ({ linkConfigSysAdminApps, handleSelection, selectedAppUuid }) => {
  const theme = useTheme();
  return (
    <FormControl size="small" >
      <InputLabel id="link-config-sysadmin-select-label">Link config sysadmin apps</InputLabel>
      <Select
        labelId="link-config-sysadmin-select-label"
        id="link-config-sysadmin-select"
        value={selectedAppUuid}
        placeholder={""}
        variant="outlined"
        onChange={handleSelection}
        label={"Link config sysadmin apps"}
        sx={{ minWidth: "300px", borderColor: theme.palette.primary.main }}
      >
        {linkConfigSysAdminApps.map((app, index) => {
          return (
            <MenuItem value={app.uuid} key={"link_config_sysadmin_app_" + index}><strong>{getLinkConfigSysAdminTitle(app.tags)}</strong>&nbsp;{app.uuid}&nbsp;{app.fullName}&nbsp;<i>{getSourceTag(app.tags)}</i></MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export const SysAdminClientEmails = ({ selectedCompany }) => {
  const sysAdminApps = useSelector(state => state.appsSysAdmin.list);
  
  const [selectedCompanySubscribedApps, setSelectedCompanySubscribedApps] = useState([]);
  const [selectedCompanySubscribedAppsArgs, setSelectedCompanySubscribedAppsArgs] = useState(null);
  
  const linkConfigSysAdminApps = sysAdminApps.filter((sysAdminApp) => {
    let linkConfigFlag = false;
    sysAdminApp.tags.forEach((tag) => {
      if (tag.includes("link_config_sysadmin__")) {
        linkConfigFlag = true
      }
    })
    let subscribedAppFlag;
    subscribedAppFlag = selectedCompanySubscribedApps.some((subbedApp) => subbedApp.developerAppUuid === sysAdminApp.uuid);
    return linkConfigFlag && subscribedAppFlag;
  })

  useEffect(() => {
    if (selectedCompany && selectedCompany.uuid) {
      if (selectedCompany.name) console.log("selected company", selectedCompany.name);
      setSelectedCompanySubscribedAppsArgs({ url: Constants.SERVER_SYSADMIN_CUS_SUBBED_APPS_URL + selectedCompany.uuid });
      setSelectedCompanySubscribedApps([])
      resetClientEmails();
    }
  }, [selectedCompany])

  const handleSelectedCompanySubscribedApps = (res) => {
    console.warn("selected company subscriptions:", res);
    if (res) {
      setSelectedCompanySubscribedApps(res);
    }
  }
  // end company apps logic
  
  const {
    selectedAppDict,
    handleSelection,
    handleSelectedAppData,
    handleDisplayConfig,
    handleClientEmail,
    handleEmailSelection,
    handleAttributesUpdate,
    handleAttributesUpdateSuccess,
    handleUpdatedAttributes,
    resetClientEmails
  } = useClientEmails(selectedCompany, selectedCompanySubscribedApps);

  return (
    <>
      {/* get company subbed apps */}
      <DirectRequest requestArgs={selectedCompanySubscribedAppsArgs} afterProcess={handleSelectedCompanySubscribedApps}/>
      {/* get selected app data */}
      <DirectRequest requestArgs={selectedAppDict.getDataArgs} afterProcess={handleSelectedAppData}/>
      {/* get selected app display configs (occurs post get data) */}
      {selectedAppDict.getDisplayConfigsArgs && (
        <DirectRequest requestArgs={selectedAppDict.getDisplayConfigsArgs[0]} afterProcess={handleDisplayConfig}/>
      )}
      {/* get client emails (occurs post get display configs) */}
      {selectedAppDict.getClientEmailArgs && (
        <DirectRequest requestArgs={selectedAppDict.getClientEmailArgs[0]} afterProcess={handleClientEmail}/>
      )}
      {/* post update to clientEmail attributes property */}
      <DirectRequest requestArgs={selectedAppDict.updateArgs} afterProcess={handleAttributesUpdateSuccess}/>
      {/* get updated clientEmail for new attributes property */}
      <DirectRequest requestArgs={selectedAppDict.getUpdatedAttributesArgs} afterProcess={handleUpdatedAttributes}/>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SysAdminLinkConfigAppSelect
            linkConfigSysAdminApps={linkConfigSysAdminApps}
            handleSelection={handleSelection}
            selectedAppUuid={selectedAppDict.uuid}
          />
        </Grid>
        
        {selectedAppDict.isReady && (
          <>
            {selectedAppDict.clientEmailUuid ? (
              <ClientEmail
                clientEmail={selectedAppDict.clientEmails.find(clientEmail => clientEmail.uuid === selectedAppDict.clientEmailUuid)}
                handleEmailSelection={handleEmailSelection}
                handleAttributesUpdate={handleAttributesUpdate}
              />
            ) : (
              selectedAppDict.clientEmails?.length > 0 ? (
                <Grid item xs={12} container spacing={1}>
                  {selectedAppDict.clientEmails.map((clientEmail) => (
                    <ClientEmailSummary
                      key={clientEmail.uuid}
                      clientEmail={clientEmail}
                      handleSelection={handleEmailSelection}
                    />
                  ))}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography><strong>No emails for this app</strong></Typography>
                </Grid>
              )
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default SysAdminClientEmails;