import { Grid, IconButton, Divider } from "@mui/material";
import { useClientEmailContent } from "../hooks/useClientEmailContent";
import DirectRequest from "../../../../API/requests/DirectRequest";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const ClientEmailSummary = ({clientEmail, handleSelection}) => {
  const {
    clientEmailContent,
    getClientEmailContentArgs,
    handleClientEmailContent
  } = useClientEmailContent(clientEmail.uuid)


  return (
    <>
      <DirectRequest requestArgs={getClientEmailContentArgs} afterProcess={handleClientEmailContent}/>
      <Grid item xs={12} container spacing={2} sx={{ marginTop: "16px" }}>
        <Grid item xs={10} container spacing={2}>
          <Grid item xs={12} container spacing={2}>
            <Grid item>
              <strong>uuid:</strong>&nbsp;{clientEmail.uuid}
            </Grid>
            <Grid item>
              <strong>response:</strong>&nbsp;{clientEmail.emailResponseTimestamp ? "Received" : "Pending"}
            </Grid>
          </Grid>
          <Grid item xs={12}><strong>subject:</strong>&nbsp;{clientEmailContent?.subject}</Grid>
          <Grid item xs={12}><strong>emailSendTimestamp:</strong>&nbsp;{clientEmail.emailSendTimestamp}</Grid>
        </Grid>
        <Grid item xs={2} container spacing={2} alignItems={"center"} justifyContent={"center"}>
          <IconButton
            onClick={() => handleSelection(clientEmail.uuid)}
          >
            <MoreHorizIcon/>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider/>
        </Grid>
      </Grid>
    </>
  )
}

export default ClientEmailSummary;