import * as Constants from "../../../../Constants";
import { useState, useEffect } from "react"

export const useClientEmailContent = (clientEmailUuid) => {
  const [clientEmailContent, setClientEmailContent] = useState(null);
  const [getClientEmailContentArgs, setGetClientEmailContentArgs] = useState(null)

  // handle get args
  useEffect(() => {
    // console.log("useClientEmailContent - useEffect - clientEmailUuid", clientEmailUuid)
    if (clientEmailUuid) {
      const tempEmailContentArgs = {
        url: Constants.SERVER_SYSADMIN_GET_CLIENT_EMAIL_CONTENT_URL + clientEmailUuid,
        method: "GET"
      }
      setGetClientEmailContentArgs(tempEmailContentArgs)
    }
  }, [clientEmailUuid])

  const handleClientEmailContent = (res) => {
    // console.log("handleClientEmailContnet - res", res)
    const clientEmailContent = {...res};
    setClientEmailContent(clientEmailContent)
  }

  return {
    clientEmailContent,
    getClientEmailContentArgs,
    handleClientEmailContent
  }
}