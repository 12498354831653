import { useClientEmailContent } from "../hooks/useClientEmailContent";
import { useState, useRef } from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import DirectRequest from "../../../../API/requests/DirectRequest";
import OverrideAttributesForm from "./OverrideAttributesForm";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

const ClientEmail = ({clientEmail, handleAttributesUpdate, handleEmailSelection}) => {
  const {
    clientEmailContent,
    getClientEmailContentArgs,
    handleClientEmailContent
  } = useClientEmailContent(clientEmail.uuid)
  
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  }

  const handleClose = (event = null) => {
    if (event && anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
    }

    setOpen(false);
  };

  return (
    <>
      <DirectRequest requestArgs={getClientEmailContentArgs} afterProcess={handleClientEmailContent}/>
      <Grid item xs={12} container spacing={2} sx={{ padding: "8px", marginTop: "16px" }}>
        <Grid item xs={12}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="p"><strong>uuid:</strong>&nbsp;{clientEmail.uuid}</Typography>
            <IconButton
              onClick={() => handleEmailSelection("")}
            >
              <CloseIcon/>
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="p"><strong>aiReviewState:</strong>&nbsp;{clientEmail.aiReviewState}</Typography>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="p"><strong>attributes:</strong></Typography>
            <IconButton
              onClick={handleToggle}
              disabled={!clientEmail.attributes}
              ref={anchorRef}
            >
              <EditIcon/>
            </IconButton>
            <OverrideAttributesForm
              uuid={clientEmail.uuid}
              attributes={clientEmail.attributes}
              open={open}
              anchorRef={anchorRef}
              handleClose={handleClose}
              handleAttributesUpdate={handleAttributesUpdate}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <pre style={{
            backgroundColor: '#f5f5f5',
            padding: '10px', 
            borderRadius: '4px',
            fontFamily: 'monospace',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            fontSize: '14px',
            color: '#333',
          }}>
            {JSON.stringify(clientEmail.attributes, null, 2)}
          </pre>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="p"><strong>Sent:</strong>&nbsp;{clientEmail.emailSendTimestamp}</Typography>
        </Grid>
        {/* start clientEmailContent */}
        {clientEmailContent && (
          <>
            <Grid item xs={12}>
              <Typography variant="p"><strong>From:</strong>&nbsp;{clientEmailContent?.sender}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p"><strong>To:</strong>&nbsp;{clientEmailContent?.recipients.join(', ')}</Typography>
            </Grid>
            {clientEmailContent?.recipientsCC?.some(cc => cc !== '') && (
              <Grid item xs={12}>
                <Typography variant="p">
                  <strong>CC:</strong>&nbsp;{clientEmailContent.recipientsCC.filter(cc => cc !== '').join(', ')}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="p">
                <strong>Subject:</strong>&nbsp;{clientEmailContent?.subject}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p">
                <strong>Body:</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  padding: '8px'
                }} 
                dangerouslySetInnerHTML={{ __html: clientEmailContent?.body }} 
                className="email-body"
              />
            </Grid>
            {clientEmailContent?.responses?.map((response, idx) => (
              <Grid container item xs={12} key={idx} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="p">
                    <strong>Response sent:</strong>&nbsp;{clientEmail.emailResponseTimestamp}
                  </Typography>
                </Grid>
                
                <Grid item xs={12}>
                  <Typography variant="p">
                    <strong>From:</strong>&nbsp;{response.sender}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="p">
                    <strong>To:</strong>&nbsp;{response.recipients.join(', ')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="p">
                    <strong>Body:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      padding: '8px'
                    }}
                    dangerouslySetInnerHTML={{ __html: response.body }}
                    className="email-body"
                  />
                </Grid>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  );
};

export default ClientEmail;