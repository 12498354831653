import Big from 'big.js';

// converts float or integer to 2 decimal string respresentation
export const formatToTwoDecimals = (number) => {
  if (number === null || number === undefined) return "";
  
  try {
    return new Big(number).toFixed(2);
  } catch (error) {
    console.error(`formatToTwoDecimals - Error formatting number {${number}}:`, error);
    return String(number);
  }
}