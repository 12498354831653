// import Tooltip from "@mui/material/Tooltip";
// import IconButton from "@mui/material/IconButton";
import { Tooltip, IconButton, Button, Popover, TextField } from "@mui/material";
import {getTooltipTitle, getIcon, getIconButtonStyle} from "./ConfigActionUtils";
import React, {useMemo, useRef, useState} from "react";
import { config_options } from "../../../../../store/dashboardConfigsSlice";
import EditIcon from '@mui/icons-material/Edit';

export const NotesConfigActionButton = ({handleConfigUpdate, configAction, displayConfigTableRow, disableActions}) => {
  const notes = useMemo(() => {
    if (displayConfigTableRow && displayConfigTableRow.displayConfigOptions[config_options.notes]?.optionValue) {
      return displayConfigTableRow.displayConfigOptions[config_options.notes]?.optionValue;
    } else {
      return "";
    }
  }, [displayConfigTableRow])

  const title = useMemo(() => {
    if (displayConfigTableRow && displayConfigTableRow.displayConfigOptions[config_options.notes]?.optionValue) {
      return displayConfigTableRow.displayConfigOptions[config_options.notes]?.optionValue + " - click to edit";
    } else {
      return "Notes - click to edit";
    }
  }, [displayConfigTableRow])

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [message, setMessage] = useState("");

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setMessage(notes);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
    }

    setOpen(false);
  };

  const handleSubmit = () => {
    handleConfigUpdate(message);
    setOpen(false);
    setTimeout(() => {
        setMessage("");
    }, 300);
  };

  return (
    <>
      <Tooltip title={title} followCursor>
        <IconButton tabIndex={-1} sx={getIconButtonStyle(configAction)} onClick={handleToggle} disabled={disableActions} ref={anchorRef}>
          <EditIcon/>
        </IconButton>
      </Tooltip>
      {/* Popover that contains the TextField */}
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
      >
        <div style={{ padding: 10, width: "350px", borderRadius: "10px" }}>
          <TextField
            multiline
            fullWidth
            minRows={3}
            maxRows={6}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="outlined"
          />
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            size="small"
            style={{ marginTop: 8, width: "100%" }}
          >
            Save
          </Button>
        </div>
      </Popover>
    </>
  )
}

export default NotesConfigActionButton;