import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addRequestError } from "../../store/requestErrorsSlice";
import * as Constants from "../../Constants";
import { v4 as uuidv4 } from "uuid";

import { fetchToCurl } from "fetch-to-curl";

const defaultErrorMessageWhitelist = ["Failed to fetch", "Network error"];
const defaultTimeout = 8;
const defaultRetries = 3;

function GenericRequest(props) {
  // const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const [requestArgs, setRequestArgs] = useState(props.requestArgs);
  const [retries, setRetries] = useState(defaultRetries);
  const [timeout, setTimeout] = useState(defaultTimeout);
  const [whitelist, setWhitelist] = useState(defaultErrorMessageWhitelist);

  useEffect(() => {
    // if (props.requestArgs) console.log("GenericRequest props", props);
    setRequestArgs(props.requestArgs);
    if (props.timeout) setTimeout(props.timeout);
    if (props.retries) setRetries(props.retries);
    if (props.errorMessageWhitelist) setWhitelist(props.errorMessageWhitelist);
  }, [props]);

  useEffect(() => {
    let isCancelled = false;
    if (localStorage.getItem("isLoggingOut")) {
      console.log("GenericRequest.js isLoggingOut found -> Removing isLoggingOut")
      localStorage.removeItem("isLoggingOut");
      return;
    }
    if (!props.requestArgs /* || props.requestArgs === {}*/) {
      return;
    } else {

      const url = props.requestArgs.url
        ? props.requestArgs.url
        : Constants.defaultRequestOptions.url;
      const method = props.requestArgs.method
        ? props.requestArgs.method
        : Constants.defaultRequestOptions.method;
      const headers = props.requestArgs.headers
        ? props.requestArgs.headers
        : Constants.defaultRequestOptions.headers;
      const mode = props.requestArgs.mode
        ? props.requestArgs.mode
        : Constants.defaultRequestOptions.mode;
      const credentials = props.requestArgs.credentials
        ? props.requestArgs.credentials
        : Constants.defaultRequestOptions.credentials;

      const body = props.requestArgs.body ? props.requestArgs.body : Object();

      const requestOptions = {
        method: method,
        mode: mode,
        credentials: credentials,
        headers: headers,
      };


      if (!(Object.keys(body).length === 0)) {
        requestOptions.body = body;
      }

      // Attach the multipart form and remove the form header (it is added automatically) if applicable
      if (headers) {
        if (Object.keys(headers).includes("Content-Type")) {
          if (headers["Content-Type"] === "multipart/form-data") {
            const newHeaders = { ...headers };
            delete newHeaders["Content-Type"];

            console.log(newHeaders);
            if (!(Object.keys(newHeaders).length === 0)) {
              requestOptions.headers = newHeaders;
            } else {
              delete requestOptions.headers;
            }
            requestOptions.body = body;
          }
        }
      }

      const result = fetch(url, requestOptions)
        .then((res) => {
          // Perform actions on successful resolution
          const unAuthorized = res.status === 401;
          // console.log(!unAuthorized ? "this is authorized" : "this is unauthorized");
          if (unAuthorized) {
            const loginUrl = Constants.SERVER_LOGIN_URL;
            console.log(loginUrl);
            dispatch({ type: "RESET" });
            localStorage.setItem("redirectUrl", window.location.href);
            window.open(loginUrl, "_self");
            return;
          }
          return res;
        })
        .then((res) => {
          if (!res) return; // case for unauthorized - pass undefined to prevent next block from triggering DirectRequest's handleError which alters redux state
          // case for logo blob
          if ( url === Constants.SERVER_GET_COMPANY_THEME_LOGO) {
            const contentLength = res.headers.get("Content-Length");
            if (contentLength && parseInt(contentLength) > 0) {
              // If there's content, process the response as a Blob
              return res.blob();
            } else {
              // do nothing
            }
            return null;
          // case for sse ping
          } else if (url === Constants.SERVER_PING_SSE_URL) {
            return { ssePinged: true };
          // all other cases
          } else {
            return res.json();
          }
        })
        .then((resJson) => {
          // if resJson is null or undefined, then it was an empty logo blob or an unauthorized request - don't process
          if (resJson) {
            // if resJson is a blob, process
            if (resJson instanceof Blob) {
              props.processResponse(resJson);
            // otherwise check for server errors
            } else if (resJson.errorCode || resJson.error) {
              if (props.processException) {
                let cleanedServerError;
                if (resJson.error) {
                  // formats generic server errors to match server exceptions for error logging
                  cleanedServerError = {
                    errorCode: resJson.status,
                    errorMessage: resJson.message,
                    data: null,
                  };
                } else {
                  cleanedServerError = resJson;
                }
                props.processException(cleanedServerError);
                if (!props.errorLogger)
                  dispatch(
                    addRequestError({
                      error: cleanedServerError,
                      logType: "server_exception",
                      url: url,
                      body: body
                    })
                  );
              }
            // otherwise process as regular resJson
            } else {
              props.processResponse(resJson);
            }
          }
        })
        .catch((error) => {
          if (props.processCatchError && !isCancelled) {
            props.processCatchError(error);
          }
          if (!props.errorLogger && !isCancelled)
            dispatch(
              addRequestError({
                error: error,
                logType: "client_error",
                url: url,
                body: body
              })
            );
          // if (!isCancelled)
          //   !Constants.USE_DUMMY_DATA && console.error(`${method} request to ${url} error`, error);
        });
    }
    return () => {
      isCancelled = true;
    };
  }, [requestArgs]);

  return null;
}

export default GenericRequest;
