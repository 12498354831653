import { IconButton, Typography, Popper, ClickAwayListener, Tooltip } from "@mui/material";
import { iconButtonStyle } from './ConfigActionUtils';
import { useTheme } from "@mui/material/styles";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { useRef, useState } from "react";
import { config_options } from '../../../../../store/dashboardConfigsSlice';
import { getTimeAgo } from './CreateEntryConfigComponents/helpers/getTimeAgo';

const getEvent = (optionType, oldValue, newValue) => {
  // console.log("getEvent")
  // console.log("optionType", optionType, "-", typeof optionValue)
  // console.log("oldValue", oldValue, "-", typeof oldValue)
  // console.log("newValue", newValue, "-", typeof newValue)
  if (optionType === config_options.status_tracker) {
    return `changed status to ${newValue}`;
  } else if (optionType === config_options.send_email) {
    return `sent email`;
  } else if (optionType === config_options.create_entry) {
    if (newValue === "NOT_SET") return "deleted entry from CSV";
    if (oldValue === "") return "added entry to CSV"
    return `modified entry in CSV`;
  } else if (optionType === config_options.hide_row) {
    // String-boolean optionValue
    if (newValue === "true") {
      return `hid row`;
    } else {
      return `unhid row`
    }
  } else if (optionType === config_options.notes) {
    return `edited note`
  }
}

export const AuditLogConfigActionButton = ({ displayConfigTableRow, disableActions }) => {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
    console.log("audit_log ->", displayConfigTableRow?.displayConfigOptions[config_options.audit])
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const audit_log = displayConfigTableRow?.displayConfigOptions[config_options.audit_log]?.optionValue 
    ? JSON.parse(displayConfigTableRow.displayConfigOptions[config_options.audit_log].optionValue) 
    : null;
  // Now audit_log will be an array of objects you can traverse like:
  // [
  //   {
  //     optionType: "status_tracker",
  //     oldValue: null,
  //     newValue: "PENDING",
  //     eventTimestamp: "2024-11-19T22:40:48.441+00:00",
  //     createdBy: "jack@awsdev_testsandbox.com"
  //   },
  //   {
  //     optionType: "send_email",
  //     oldValue: "com.fruitiondata.server.model.domain.generic.DisplayConfigRowOption@ed8e196d",
  //     newValue: "8E83RSL51RQVF09BO2QXO587W",
  //     eventTimestamp: "2024-11-19T22:40:48.328+00:00",
  //     createdBy: "jack@awsdev_testsandbox.com"
  //   }
  // ]

  // Convert the audit_log into a sorted array by timestamp
  const sortedEntries = audit_log 
    ? [...audit_log].sort((a, b) => new Date(a.eventTimestamp) - new Date(b.eventTimestamp))
    : null;

  return (
    <>
      <Tooltip title={"Audit log"} followCursor arrow>
        <IconButton tabIndex={-1} sx={iconButtonStyle} onClick={handleToggle} disabled={disableActions}>
          <InfoIcon ref={anchorRef} sx={{ color: open ? theme.palette.primary.main : 'inherit' }}/>
        </IconButton>
      </Tooltip>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom" sx={{zIndex: 1}}>
        <ClickAwayListener onClickAway={handleClose}>
          <div style={{ display: "flex", flexDirection: "column", backgroundColor: "white", borderRadius: "10px", boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)", padding: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="p" sx={{ padding: "10px" }}><strong>Audit log</strong></Typography>
              <IconButton sx={iconButtonStyle} onClick={handleClose}><CloseIcon sx={{ fontSize: "20px" }}/></IconButton>
            </div>
            {sortedEntries && sortedEntries.map((entry, index) => (
              <Typography key={index} variant="p" sx={{ padding: "10px" }}>
                {entry.createdBy}&nbsp;{getEvent(entry.optionType, entry.oldValue, entry.newValue)}&nbsp;{getTimeAgo(entry.eventTimestamp)}
              </Typography>
            ))}
            {!sortedEntries && (
              <Typography variant="p" sx={{ padding: "10px" }}>
                <i>No events</i>
              </Typography>
            )}
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default AuditLogConfigActionButton;